<template>
	<div class="h-full ob-t">
		<div class="org-box">
			<el-input class="tree-search" v-model="filterText" show-word-limit placeholder="请输入名称" />
			<div class="scroll-bar item">
				<div class="white-body-view">
                    <el-tree id="my-tree" ref="tree" class="tree-view structure-tree scroll-bar" :data="treeData" highlight-current
                    :props="defaultProps" :default-expanded-keys="[1, 2]" node-key="Id" @node-click="handleNodeClick"
                    :auto-expand-parent="false" :expand-on-click-node="false" :filter-node-method="filterNode"
                    :default-checked-keys="checkedOrgKeys" 
					:show-checkbox="config.dataType==0"
					check-strictly> 
                    <span slot-scope="{ node, data }" class="custom-tree-node">
                        <span class="tooltip">
                            <span class="add-f-s-14">{{ data.Name }}</span>
                        </span>
                    </span>
                    </el-tree>
                </div>
			</div>
		</div>
		<div class="form-padding h-handle-table org-data">
			<el-tabs v-model="tabActiveName">
				<el-tab-pane label="项目" name="tabProject" v-if="config.dataType==1">
					<div class="tab-table">
						<div class="h-table-list">
							<div class="h-full">
								<div class="table-s">
									<el-table :v-loading="false" ref="projectTable" :data="dataSource.projectDataSource" style="width: 100%;" border highlight-current-row
									@selection-change="onProjSelectionChange" @select="onProjSelectChange" @select-all="onProjSelectChange">
										<el-table-column type="selection" width="55">
										</el-table-column>
										<el-table-column prop="Code" label="项目编码"></el-table-column>
										<el-table-column prop="Name" label="项目名称"></el-table-column>
									</el-table>
								</div>
							</div>
						</div>
						<div class="page">
							<el-pagination @size-change="onProjSizeChange" @current-change="onProjPageChange"
								:current-page="queryParam.PageIndex" :page-sizes="queryPage.sizes" :page-size="queryParam.PageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="projTotalCount">
							</el-pagination>
							<el-button type="text" class="btn-refurbish" v-on:click="onProjRefresh">刷新</el-button>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="货主" name="tabCustomer" v-if="config.dataType==0 || config.dataType==2">
					暂无
				</el-tab-pane>
				<el-tab-pane label="承运商" name="tabCarrier" v-if="config.dataType==0 || config.dataType==3">
					<div class="tab-table">
						<div class="table-func"><el-checkbox v-model="carrier.containThird" @change="onCarrierContainThird">三方承运商</el-checkbox></div>
						<div class="h-handle-table ">
							<div class="h-table-list">
								<div class="h-full">
									<div class="table-s">
										<el-table :v-loading="false" ref="carrierTable" :data="dataSource.carrierDataSource" style="width: 100%;" border highlight-current-row
										@selection-change="onCarrierSelectionChange" @select="onCarrierSelectChange" @select-all="onCarrierSelectChange">
											<el-table-column type="selection" width="55">
											</el-table-column>
											<el-table-column prop="MemberCode" label="承运商编码"></el-table-column>
											<el-table-column prop="MemberName" label="承运商名称"></el-table-column>
										</el-table>
									</div>
								</div>
							</div>
							<div class="page">
								<el-pagination @size-change="onCarrierSizeChange" @current-change="onCarrierPageChange"
									:current-page="carrierQueryParam.PageIndex" :page-sizes="queryPage.sizes" :page-size="carrierQueryParam.PageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="carrier.carrierTotalCount">
								</el-pagination>
								<el-button type="text" class="btn-refurbish" v-on:click="onCarrierRefresh">刷新</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="仓库" name="tabWarehouse" v-if="config.dataType==0 || config.dataType==4">
					暂无
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		this.getTreeselect();
		this.Event.$on('reloadChangePageList', () => this.reloadPageList());
	},
	data() {
		return {
			queryPage:{
				sizes: ['自定义-', 10, 50, 100, 200, 500]
			},
			queryParam: {
				PageIndex: 1,
				PageSize: 10,
				Params:{}
			},
			carrierQueryParam: {
				PageIndex: 1,
				PageSize: 10,
				Params:{}
			},
			projTotalCount:0,
			projSelection: [],
			projSelectRows:[],
			checkedOrgKeys:[],
			carrier:{
				containThird:0,
				carrierTotalCount:0,
				carrierSelection: [],
				carrierSelectRows:[],
				checkedCarrierKeys:[]
			},
			dataSource: {
				ColDefs: {},
				Result: [],
				TotalCount: 0,
				projectDataSource:[],
				carrierDataSource:[]
			},
			tabActiveName:'tabCustomer',
			checked: false,
			treeExpandAll: false,
			treeNodeKey: 'Id',
			treeData: [],
			selectTreeNode: null,
			filterText: '',
			treeCheckedID: '',
			orgType: '0',
            treeData: [],
            treeExpandAll: true,
            treeNodeKey: '',
            checkedID:'',
            defaultProps: {
                children: 'Children',
                label: 'Name'
            },
            selectItem: {},
			ignoreFlag:0
		};
	},
	props: {
		config: {
			dataType:{
				type:Number,
				default:1
			}
		}
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		},
		treeExpandAll(e) {
			this.treeExpandAll = e
			},
		checkedID() {
			this.$nextTick(() => {
				this.$refs.tree.setCurrentKey(this.checkedID)
			})
		},
		'config.dataType': {
			handler(curVal, oldVal) {
				if(curVal!=oldVal){
					var dataType = curVal;
					if(dataType==0){
						this.tabActiveName='tabCustomer';
					}else if(dataType==1){
						this.tabActiveName='tabProject';
					}else if(dataType==2){
						this.tabActiveName='tabCustomer';
					}else if(dataType==3){
						this.tabActiveName='tabCarrier';
					}else if(dataType==4){
						this.tabActiveName='tabWarehouse';
					}
				}
			},
			deep: true,
			immediate: true
		},
	},
	computed:{
		setCurrentTab:{
			get(){
				
			},
			set(){}
		}
	},
	methods: {
		//刷新
		reloadPageList() {
			this.getTreeselect();
			this.onProjPageChange();
			this.onCarrierPageChange();
		},

		//项目
		onProjSizeChange(){
			this.getList();
		},
		onProjPageChange(){
			this.getList();
		},
		onProjRefresh(){
			this.getList();
		},
		onProjSelectionChange(selection){
		},
		onProjSelectChange(selection, row){
			if(this.ignoreFlag==0){
				this.saveSelectedData(this.projSelection,selection,1);
				this.projSelection = selection;
			}else{
				this.ignoreFlag=0;
			}
		},
		getList: function() {
			var me = this;
			me.saveSelectedData(null,me.projSelection,1);
			me.$ajax.query('omsapi/projectinfo/getpagelist', 'post', me.queryParam, result => {
				me.dataSource.projectDataSource = result.Result;
				me.setProjSelectedRow();
			});
		},
		//设置选中项
		setProjSelectedRow(){
			var me = this;
			if(me.projSelection.length>0 && me.dataSource.projectDataSource.length>0){
				me.dataSource.projectDataSource.forEach((item, index) => {
					var tempIndex = -1;
					me.projSelectRows.forEach((subItem,subIndex)=>{
						if(item.Id==subItem.Id){
							tempIndex = index;
						}
					});
					if(tempIndex>=0){
						me.$nextTick(()=>{
							me.$refs.projectTable.toggleRowSelection(me.dataSource.projectDataSource[tempIndex],true);
						});
						me.ignoreFlag = 1;
					}
				});
			}
		},
		//保存选中项
		saveSelectedData(oldSelection,newSelection,dataType){
			var selectRows = [];
			if(dataType==1){
				selectRows = this.projSelectRows;
			}else if(dataType==2){
				selectRows = this.carrier.carrierSelectRows;
			}
			if(oldSelection && oldSelection.length>0){
				oldSelection.forEach((item,index)=>{
					var tempIndex = -1;
					selectRows.forEach((subItem,subIndex)=>{
						if(dataType==1){
							if(item.Id==subItem.Id){
								tempIndex = subIndex;
							}
						}else if(dataType==2){
							if(item.MemberId==subItem.MemberId){
								tempIndex = subIndex;
							}
						}
					});
					if(tempIndex<0){
						selectRows.splice(tempIndex,1);
					}
				});
			}
			if(newSelection && newSelection.length>0){
				newSelection.forEach((item,index)=>{
					var tempIndex = -1;
					selectRows.forEach((subItem,subIndex)=>{
						if(dataType==1){
							if(item.Id==subItem.Id){
								tempIndex = subIndex;
							}
						}else if(dataType==2){
							if(item.MemberId==subItem.MemberId){
								tempIndex = subIndex;
							}
						}
					});
					if(tempIndex<0){
						selectRows.push(newSelection[index]);
					}
				});
			}
		},

		//承运商
		onCarrierSizeChange(val){
			this.carrierQueryParam.PageSize = val;
			this.getCarrierList();
		},
		onCarrierPageChange(val){
			this.carrierQueryParam.PageIndex = val;
			this.getCarrierList();
		},
		onCarrierRefresh(){
			this.getCarrierList();
		},
		onCarrierSelectionChange(selection){
		},
		onCarrierSelectChange(selection, row){
			if(this.ignoreFlag==0){
				this.saveSelectedData(this.carrier.carrierSelection,selection,2);
				this.carrier.carrierSelection = selection;
			}else{
				this.ignoreFlag=0;
			}
		},
		onCarrierContainThird(){
			this.carrierQueryParam.PageIndex = 1;
			this.getCarrierList();
		},
		//设置选中项
		setCarrierSelectedRow(){
			var me = this;
			if(me.carrier.carrierSelectRows.length>0 && me.dataSource.carrierDataSource.length>0){
				me.dataSource.carrierDataSource.forEach((item, index) => {
					var tempIndex = -1;
					me.carrier.carrierSelectRows.forEach((subItem,subIndex)=>{
						if(item.MemberId==subItem.MemberId){
							tempIndex = index;
						}
					});
					if(tempIndex>=0){
						me.$nextTick(()=>{
							me.$refs.carrierTable.toggleRowSelection(me.dataSource.carrierDataSource[tempIndex],true);
						});
						me.ignoreFlag = 1;
					}
				});
			}
		},
		getCarrierList: function() {
			var me = this;
			me.carrierQueryParam.PageParams= {ContainThird:this.carrier.containThird}
			me.saveSelectedData(null,me.carrier.carrierSelection,2);
			me.$ajax.query('omsapi/memberinfo/getPageListByPerm', 'post', me.carrierQueryParam, result => {
				me.dataSource.carrierDataSource = result.Result;
				me.carrier.carrierTotalCount = result.TotalCount;
				me.setCarrierSelectedRow();
			});
		},

		//  查询左侧tree下拉树结构
		getTreeselect() {
			var _this = this;
			this.$ajax.send('omsapi/organization/getLogTreeList', 'get', { id: 0, type: parseInt(_this.orgType) }, data => {
				this.treeData = data.Result;
				if (this.treeData != null && this.treeData.length > 0) {
					this.treeCheckedID = this.treeData[0].Id;
					this.selectTreeNode = this.treeData[0];
				}
			});
		},
		// 选中每一个子节点---对应右边明细
		handleNodeClick(data) {
			this.selectTreeNode = data;
			this.queryParam.Params.SigningUnitId = data.Id;
			this.carrierQueryParam.Params.OrganizationId = data.Id;
			this.getList();
			this.getCarrierList();
		},
		handleClick(tab, event) {
			this.orgType = tab.name;
			this.getTreeselect();
		},
        filterNode(value, data) {
			if (!value) return true;
			return data.Name.indexOf(value) !== -1;
        },
		getSelectedAll(){
			var data= {
				OrgList:this.$refs.tree.getCheckedNodes(),
				ProjectList:this.projSelectRows,
				CarrierList:this.carrier.carrierSelectRows
			};
			var result = this.handleData(data);
			return result;
		},
		handleData(data){
			var result = {};
			if(data){
				if(data.OrgList && data.OrgList.length>0){
					var list = [];
					data.OrgList.forEach((item,index)=>{
						var model={DataType:0,OrgId:item.Id,OrgCode:item.Code,OrgName:item.Name};
						list.push(model);
					});
					result.OrgList = list;
				}
				if(data.ProjectList && data.ProjectList.length>0){
					var list = [];
					data.ProjectList.forEach((item,index)=>{
						var model={DataType:1,OrgId:item.Id,OrgCode:item.Code,OrgName:item.Name,ProjectId:item.Id,ProjectCode:item.Code,ProjectName:item.Name};
						list.push(model);
					});
					result.ProjectList = list;
				}
				if(data.CarrierList && data.CarrierList.length>0){
					var list = [];
					data.CarrierList.forEach((item,index)=>{
						var model={DataType:3,OrgId:item.OrganizationId,OrgCode:item.OrganizationCode,OrgName:item.OrganizationName,CarrierId:item.MemberId,CarrierCode:item.MemberCode,CarrierName:item.MemberName};
						list.push(model);
					});
					result.CarrierList = list;
				}
			}
			return result;
		}
	},
	components: {
	}
};
</script>

<style>
.ob-t {
	display: flex;
}

.ob-t .h-table-list {
	padding-top: 10px;
}

.org-box {
	/* background-color: #fbfbfb; */
	border: 1px solid #e5e6eb;
	width: 250px;
	display: flex;
	flex-direction: column;
	margin-right: 8px;
}
.menu-type {
	width: 180px;
	float: left;
}
.menu-type .el-tabs__item {
	padding: 0 15px !important;
}
.menu-add {
	width: 36px;
	height: 24px;
	line-height: 24px;
	float: right;
	text-align: center;
	border-bottom: solid 1px #e0e0e0;
	cursor: pointer;
}
.org-box .btn-add {
	background-color: #f5f7fa;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin: 0 4% 10px; */
	padding: 0 15px;
	color: #666;
	cursor: pointer;
}

.org-box .btn-add i {
	font-size: 12px;
	font-weight: bold;
	color: #333;
}

.org-box .tree-search {
	width: 92%;
	margin: 10px 4%;
}

.org-box .scroll-bar {
	flex: 1;
	height: 0;
}

.org-box .white-body-view {
	height: 100%;
	overflow-y: auto;
}

.org-box .el-tree {
	background-color: transparent;
}

.org-box .el-tree .el-tree-node__content {
	display: flex;
	flex-direction: row;
	height: 36px;
}

.org-box .el-tree .custom-tree-node {
	font-size: 12px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 10px;
}

.org-box .el-tree .custom-tree-node * {
	font-size: inherit;
}

.org-box .el-card__body {
	padding: 0px;

	overflow-y: auto;
}
.white-body-view {
}

.structure-tree {
  .el-scrollbar .el-scrollbar__wrap {
  }

  #my-tree .el-tree>.el-tree-node {
    min-width: 100%;
    display: inline-block;
  }

  .el-tree-node__content {
  }

  .tooltip {
    margin-right: 5px;
    font-size: 13px;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 4px;
  }

  .operation-view {
    padding: 0px 5px;
    margin-left: 5px;
    color: #3473F4 !important;
    display: flex;
    align-items: center;
  }

  .operation-view i {
    color: inherit !important;
  }

  .small-operation-btn {
    margin: 0px 3px;
  }
}

.el-icon-plus {
  color: #c0c4cc !important;
}

.el-icon-edit {
  color: #c0c4cc !important;
}

.el-icon-delete {
  color: #c0c4cc !important;
}

.el-icon-plus:hover {
  color: #303133 !important;
}

.el-icon-edit:hover {
  color: #303133 !important;
}

.el-icon-delete:hover {
  color: #303133 !important;
}

::v-deep .el-tree {
  color: #333333 !important;
}
.org-data .page{ position: relative !important;}
.table-func{ text-align: right; background-color: #f3f3f3; padding: 5px;}
</style>
